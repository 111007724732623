import { useRecoilState, useRecoilValue } from 'recoil';
import { serviceSelection } from './store';
import { useLazyLoadQuery } from 'react-relay';
import { FC, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import {
    StepThreeFormQuery$data,
    StepThreeFormQuery as StepThreeFormQueryInterface,
} from './__generated__/StepThreeFormQuery.graphql';
import {
    FieldTypes,
    IIsValidCallback,
    IServiceValues,
    IServiceValuesMap,
    RenderServices,
} from '../servicerenderer/ServiceRenderer';
import { serviceValuesAtom } from './store/wizard.atoms';
import * as React from 'react';
import { ColumnLayout, Container, Header, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { ValueWithLabel } from '../common/ValueWithLabel';
import { ServiceOrder } from './templateMappings';
import { getSortedServices } from './getSortedServices';

interface Props {
    onCancel?: () => void;
    onSubmit?: () => void;
    giveState?: (state: any) => void;
    setNextClickHandler?: (handler: () => boolean) => void;
}

export const StepThreeFormQuery = graphql`
    query StepThreeFormQuery($input: MosaicServiceConfigurationsInput) {
        mosaicServiceConfiguration_list(input: $input) {
            count
            limit
            offset
            total
            services {
                mosaicServiceId
                serviceDependencyIds
                name
                description
                infoUrl
                fields {
                    name
                    description
                    required
                    handle
                    type
                    options {
                        name
                        description
                        handle
                        value
                    }
                }
            }
        }
    }
`;

export const StepThreeForm: FC<Props> = (props) => {
    const selServices = useRecoilValue(
        serviceSelection,
    ) as StepThreeFormQuery$data['mosaicServiceConfiguration_list']['services'];
    const [serviceValues, setServiceValues] = useRecoilState(serviceValuesAtom);
    const [nextWasClicked, setNextWasClicked] = useState(false);

    const { mosaicServiceConfiguration_list: servResp } = useLazyLoadQuery<StepThreeFormQueryInterface>(
        StepThreeFormQuery,
        {
            input: {
                mosaicServiceIds: selServices.map((serv) => serv.mosaicServiceId),
            },
        },
    );

    const areServiceValuesValidCallbackRef = useRef<IIsValidCallback>(null);

    const registerAreServiceValuesValidCallback = (callback: IIsValidCallback) => {
        areServiceValuesValidCallbackRef.current = callback;
    };

    const serviceValuesChanged = (values: IServiceValuesMap) => {
        setServiceValues(values);
    };

    const handleCancel = () => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    const handleSubmit = (e: SyntheticEvent) => {
        e.preventDefault();
        if (props.onSubmit) {
            props.onSubmit();
        }
    };

    const sortedServices = useMemo(() => getSortedServices(servResp.services, ServiceOrder), [servResp, ServiceOrder]);

    const onNextClicked = (): boolean => {
        setNextWasClicked(true);
        // Call func on service renderer to get it's validation status
        return areServiceValuesValidCallbackRef.current ? areServiceValuesValidCallbackRef.current() : true;
    };

    useEffect(() => {
        props.setNextClickHandler ? props.setNextClickHandler(onNextClicked) : null;
    });

    return (
        <>
            {RenderServices({
                services: sortedServices,
                getInitialServiceValues: () => serviceValues,
                isOnChangeValidationEnabled: nextWasClicked,
                registerIsValidCallback: registerAreServiceValuesValidCallback,
                serviceValuesChanged: serviceValuesChanged,
            })}
        </>
    );
};

interface ServiceReviewProps {
    service: StepThreeFormQuery$data['mosaicServiceConfiguration_list']['services'][0];
    // TODO: Fix interface
    serviceValues: IServiceValues;
}

const ServiceReview: FC<ServiceReviewProps> = (props) => {
    const { service, serviceValues } = props;

    const getValues = (
        field: StepThreeFormQuery$data['mosaicServiceConfiguration_list']['services'][0]['fields'][1],
        fConf,
    ): string => {
        const value = fConf ?? '';

        switch (field.type) {
            case FieldTypes.checkbox_list:
            case FieldTypes.multiselect:
                return value.split(',').join(', ');
            default:
                return value;
        }
    };

    const colCount = 2;
    const cols = [];
    let colCurs = 0;
    service?.fields?.forEach((f, index) => {
        if (!cols[colCurs]) {
            cols.push([]);
        }
        const val = getValues(f, serviceValues[f.handle]);
        cols[colCurs].push(
            <ValueWithLabel key={index} label={f.name} required={f.required}>
                {val}
            </ValueWithLabel>,
        );

        colCurs++;
        if (colCurs >= colCount) {
            colCurs = 0;
        }
    });

    const rendCols = cols.map((c, i) => (
        <SpaceBetween key={i} size={'l'}>
            {c}
        </SpaceBetween>
    ));

    return (
        <Container
            header={
                <Header
                    variant="h2"
                    info={
                        <Link variant="info" target="_blank" rel="noopener noreferrer" href={service.infoUrl}>
                            Info
                        </Link>
                    }
                >
                    {service.name}
                </Header>
            }
        >
            <ColumnLayout columns={colCount} variant="text-grid">
                {rendCols}
            </ColumnLayout>
        </Container>
    );
};

export const StepThreeReview = () => {
    const selServices = useRecoilValue(
        serviceSelection,
    ) as StepThreeFormQuery$data['mosaicServiceConfiguration_list']['services'];
    if (!selServices.length) {
        return <></>;
    }
    const serviceConf = useRecoilValue(serviceValuesAtom);
    const { mosaicServiceConfiguration_list: servResp } = useLazyLoadQuery(StepThreeFormQuery, {
        input: {
            mosaicServiceIds: selServices.map((serv) => serv.mosaicServiceId),
        },
    }) as StepThreeFormQuery$data;

    const sortedServices = getSortedServices(servResp.services, ServiceOrder);
    return (
        <SpaceBetween size="l">
            {sortedServices
                .filter((s) => !!s?.fields?.length)
                .map((s, i) => (
                    <ServiceReview key={i} service={s} serviceValues={serviceConf[s.mosaicServiceId] || {}} />
                ))}
        </SpaceBetween>
    );
};
