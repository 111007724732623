import { FC } from 'react';
import { IFieldProps } from './ServiceRenderer';

interface Props {
    label: string;
    required: boolean;
}

export const FieldLabel: FC<Props> = (props) => {
    return (
        <span>
            {props.label} {props.required ? '' : <i>- optional</i>}
        </span>
    );
};
