import { CognitoUser } from "amazon-cognito-identity-js";
import { atom, selector } from "recoil";

export const user = atom({
    key: 'user',
    default: null,
    dangerouslyAllowMutability: true,
});

export const userAlias = selector({
    key: 'userAlias',
    get: ({get}) => {
        const userObj = get(user);

        // duckTyping CognitoUser
        const isCognitoUser = typeof userObj === 'object' && !!userObj && 'username' in userObj;

        const username = isCognitoUser ? (userObj as CognitoUser).getUsername() : undefined;
        return username ? username.split('_')[1] : 'unknown'; // example username: "AmazonFederate_haiglej"
    }
})
