import { SpaceBetween } from '@amzn/awsui-components-react';
import LandingPageTable from './LandingPageTable';

const LandingPageContent = () => {
    return (
        <>
            <SpaceBetween direction="vertical" size="l">
                <LandingPageTable />
            </SpaceBetween>
        </>
    );
};

export default LandingPageContent;
