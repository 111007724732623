// your-app-name/src/RelayEnvironment.js
import { Environment, Network, RecordSource, Store, GraphQLResponse, Observable } from 'relay-runtime';
import fetchGraphQL from './fetchGraphQL';
import { EnvironmentKey } from 'recoil-relay';
import API, { graphqlOperation } from '@aws-amplify/api';

// Relay passes a "params" object with the query name and text. So we define a helper function
// to call our fetchGraphQL utility with params.text.
async function fetchRelay(params: any, variables: any) {
    console.log(`fetching query ${params.name} with ${JSON.stringify(variables, null, 2)}`);
    return fetchGraphQL(params.text, variables);
}

function subscribe(operation, variables) {
    return Observable.from(API.graphql(graphqlOperation(operation.text, variables))).map((resp) => resp.value);
}

export const RelayEnvironmentKey = new EnvironmentKey('MOSAIC');

// Export a singleton instance of Relay Environment configured with our network function:
export default new Environment({
    network: Network.create(fetchRelay, subscribe),
    store: new Store(new RecordSource()),
});
