import { StepOneForm } from '../wizard/StepOneForm';
import { Container, Flashbar } from '@amzn/awsui-components-react';
import Wizard, { WizardStep } from '../wizard/Wizard';
import { StepTwoForm } from '../wizard/StepTwoForm';
import { createElement, Suspense, useReducer, useState } from 'react';
import { StepThreeForm } from '../wizard/StepThreeForm';
import { useNavigate } from 'react-router-dom';
import { StepFourForm } from '../wizard/StepFourForm';
import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';
import {
    CreateMutation as CreateMutationType,
    CreateMutation$data,
    SetProductInput,
} from './__generated__/CreateMutation.graphql';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { userAlias } from '../auth/store';
import { productDefinition, serviceSelection } from '../wizard/store';
import { Environment, getEnvironment } from '../configuration/environment';
import { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar/interfaces';
import * as React from 'react';
import { ulid } from 'ulid';

const CreateMutation = graphql`
    mutation CreateMutation($input: SetProductInput!) {
        product_set(input: $input) {
            productId
        }
    }
`;

// Using a reducer isn't necessary if we always just clear all notifications when onDimiss is called on a notification.
// If all messages are generic then I think they should all be cleared when any one is cleared.
// If they aren't generic maybe we would want to individually clear them using remove
function flashbarItemsReducer(state, action) {
    switch (action.type) {
        case 'add':
            return [...state, action.item];
        case 'remove':
            return state.filter((item) => item.id !== action.item.id);
        case 'reset':
            return [];
        default:
            throw new Error('Invalid action given to flashbarItemsReducer');
    }
}

const errorFlashbarItem = {
    type: 'error' as FlashbarProps.Type,
    dismissible: true,
    loading: false,
    header: 'Server Error',
    content: <>There was an error processing your request. Please try again.</>,
};

const CreateContent = () => {
    // TODO: we are passing in the userAlias in the request, but we should update workspace_set to use alias passed directly to Lambda already
    const user = useRecoilValue(userAlias);
    const getProdDef = useRecoilCallback(
        ({ snapshot }) =>
            async () => {
                return await snapshot.getPromise(productDefinition);
            },
        [],
    );
    const getServiceSel = useRecoilCallback(
        ({ snapshot }) =>
            async () => {
                return snapshot.getPromise(serviceSelection);
            },
        [],
    );

    const navigate = useNavigate();
    const [activeStepIndex, setActiveStepIndex] = useState(0);

    const [commitMutation, isMutationInFlight] = useMutation<CreateMutationType>(CreateMutation);
    const [flashbarItems, flashbarItemsDispatch] = useReducer(flashbarItemsReducer, []);

    const stringToSubscribersArray = (subscribers: string): { actorType: string; identity: string }[] => {
        return subscribers.split(';').map((sub) => ({ actorType: 'AMAZON_USER', identity: sub.trim() }));
    };

    const handleSubmit = async () => {
        const productDef = await getProdDef();
        const servSel = await getServiceSel();

        // TODO: we need to add some distinguishing attribute to determine if a service offering is a Manually Managed Service Offering (uses Beacon Task Plan resource)
        const servNames = servSel.map((serv) =>
            serv.name === 'Device Registration' || serv.name === 'Frustration Free Setup'
                ? serv.name
                : `Beacon ${serv.name}`,
        );

        const input: SetProductInput = {
            name: productDef.name,
            description: productDef.description,
            definition: {
                deviceName: productDef.name,
                productLine: productDef.productLine,
                tentativeStreetDate: productDef.streetDate,
                confluenceUrl: productDef.confluenceUrl,
            },
            subscribers: stringToSubscribersArray(productDef.ltpm),
        };

        commitMutation({
            variables: {
                input,
            },
            onCompleted(data: CreateMutation$data) {
                console.log('product_set response: ', data.product_set);
                navigate(`/products/${data.product_set.productId}`);
            },
            onError(data) {
                console.error('Failed to invoke product_set API', data);
                const errorNotification = {
                    ...errorFlashbarItem,
                    id: ulid(),
                    onDismiss: () => {
                        flashbarItemsDispatch({ type: 'reset' });
                    },
                };
                flashbarItemsDispatch({ type: 'add', item: errorNotification });
            },
        });
    };

    const environment = getEnvironment();

    const createStep: (title: string, component: any) => WizardStep<void> = (title, component) => ({
        component: ({ state, giveState, inError, setNextClickHandler }) => {
            return component == undefined ? (
                <code>🚧 {title} 🚧</code>
            ) : (
                <Suspense fallback={'Loading...'}>
                    {createElement(component, {
                        state,
                        giveState,
                        setNextClickHandler,
                        setActiveStepIndex,
                        inError,
                    })}
                </Suspense>
            );
        },
        props: { title },
        isLoading: () => false,
    });

    const Step1 = createStep('Define product', StepOneForm);
    const Step2 = createStep('Select template', StepTwoForm);
    const Step3 = createStep('Configure inputs', StepThreeForm);
    const Step4 = createStep('Review and create', StepFourForm);

    return (
        <div>
            <Container>
                <Flashbar items={flashbarItems} />
                <Wizard
                    activeStepIndex={activeStepIndex}
                    setActiveStepIndex={setActiveStepIndex}
                    editMode={false}
                    steps={environment === Environment.prod ? [Step1, Step4] : [Step1, Step2, Step3, Step4]}
                    submit="Create"
                    onCancel={() => {
                        navigate('/');
                    }}
                    onSubmit={handleSubmit}
                    forceLoading={isMutationInFlight}
                />
            </Container>
        </div>
    );
};

export default CreateContent;
