import { Environment, getEnvironment } from './environment';

interface ICognitoConf {
    REGION: string;
    USER_POOL_ID: string;
    APP_CLIENT_ID: string;
    SUB_DOMAIN: string;
    REDIRECT: string;
}

const COGNITO_MAPPING = {
    [Environment.beta]: {
        REGION: 'us-west-2',
        USER_POOL_ID: 'us-west-2_zXVBH9Fod',
        APP_CLIENT_ID: '7pr05c2jaki09aoju759nl0k12',
        SUB_DOMAIN: 'mosaic-portal-beta',
        REDIRECT: window.location.origin + '/',
    },
    [Environment.test]: {
        REGION: 'us-west-2',
        USER_POOL_ID: 'us-west-2_zXVBH9Fod',
        APP_CLIENT_ID: '7pr05c2jaki09aoju759nl0k12',
        SUB_DOMAIN: 'mosaic-portal-beta',
        REDIRECT: window.location.origin + '/',
    },
    [Environment.prod]: {
        REGION: 'us-east-1',
        USER_POOL_ID: 'us-east-1_73c3iu0cm',
        APP_CLIENT_ID: '544h3ru2ipjf9d13e0bklcglme',
        SUB_DOMAIN: 'mosaic-portal-prod',
        REDIRECT: window.location.origin + '/',
    },
};

export const getCognitoConf = (): ICognitoConf => {
    return COGNITO_MAPPING[getEnvironment()];
};
