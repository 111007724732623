/**
 * @generated SignedSource<<99e99c43c7ba050c5ae19dddea6e5686>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type DetailsSubscription$variables = {
  name: string;
};
export type DetailsSubscription$data = {
  readonly subscribe: {
    readonly data: any;
    readonly name: string;
  } | null;
};
export type DetailsSubscription = {
  response: DetailsSubscription$data;
  variables: DetailsSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "concreteType": "Channel",
    "kind": "LinkedField",
    "name": "subscribe",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "data",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DetailsSubscription",
    "selections": (v1/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DetailsSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b935d11ae7570462d50e253097039bcd",
    "id": null,
    "metadata": {},
    "name": "DetailsSubscription",
    "operationKind": "subscription",
    "text": "subscription DetailsSubscription(\n  $name: String!\n) {\n  subscribe(name: $name) {\n    name\n    data\n  }\n}\n"
  }
};
})();

(node as any).hash = "36a4b1452065a8deb5280b3772777201";

export default node;
