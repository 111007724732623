import * as React from 'react';
import { Header, Container, Form, SpaceBetween, Link, Tiles, Cards, ColumnLayout } from '@amzn/awsui-components-react';
import { FC, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { productDefinition, serviceSelection } from './store';
import { useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {
    StepTwoFormQuery$data,
    StepTwoFormQuery as StepTwoFormQueryInterface,
} from './__generated__/StepTwoFormQuery.graphql';
import { ServiceOrder, TemplateMappings } from './templateMappings';
import basic from '../images/basic.png';
import echo from '../images/echo.png';
import ereader from '../images/ereader.png';
import firetv from '../images/firetv.png';
import tablet from '../images/tablet.png';
import blink from '../images/blink.png';
import eero from '../images/eero.png';
import healthandwellness from '../images/healthandwellness.png';
import ring from '../images/ring.png';
import smarthome from '../images/smarthome.png';
import { TilesProps } from '@amzn/awsui-components-react/polaris/tiles/interfaces';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import { selectedTemplate } from './store/wizard.atoms';
import { ValueWithLabel } from '../common/ValueWithLabel';
import { getSortedServices } from './getSortedServices';

interface Props {
    onCancel?: () => void;
    onSubmit?: () => void;
    giveState?: (state: any) => void;
    setNextClickHandler?: (handler: () => boolean) => void;
}

const StepTwoFormQuery = graphql`
    query StepTwoFormQuery {
        mosaicServiceConfigurationOverview_list {
            count
            limit
            offset
            total
            services {
                mosaicServiceId
                serviceDependencyIds
                name
                description
                infoUrl
            }
        }
    }
`;

const imgStyle = {
    height: '6em',
};

const ProductLineTemplates = {
    Echo: ['echoonthego', 'echoheadless', 'echomultimodal'],
    'e-Reader': ['ereader'],
    FireTV: ['firetv1p', 'smp'],
    'Fire TV Edition': ['firetvedition'],
    Tablet: ['tablet'],
    'Health & Wellness': ['healthandwellness'],
    'Smart Home': ['smarthome'],
    CoRo: ['coro'],
    Ring: ['ring'],
    Blink: ['blink'],
    eero: ['eero'],
};

// TODO: Merge template mappings into these objects?
const Templates = {
    basic: {
        label: 'Basic',
        image: <img src={basic} style={imgStyle} alt="placeholder" />,
        value: 'basic',
    },
    eero: {
        label: 'eero',
        image: <img src={eero} style={imgStyle} alt="placeholder" />,
        value: 'eero',
    },
    ring: {
        label: 'Ring',
        image: <img src={ring} style={imgStyle} alt="placeholder" />,
        value: 'ring',
    },
    blink: {
        label: 'Blink',
        image: <img src={blink} style={imgStyle} alt="placeholder" />,
        value: 'blink',
    },
    healthandwellness: {
        label: 'Health & Wellness',
        image: <img src={healthandwellness} style={imgStyle} alt="placeholder" />,
        value: 'healthandwellness',
    },
    coro: {
        label: 'CoRo',
        image: <img src={basic} style={imgStyle} alt="placeholder" />,
        value: 'coro',
    },
    echoonthego: {
        label: 'Echo On The Go',
        image: <img src={echo} style={imgStyle} alt="placeholder" />,
        value: 'echoonthego',
    },
    echoheadless: {
        label: 'Echo Headless',
        image: <img src={echo} style={imgStyle} alt="placeholder" />,
        value: 'echoheadless',
    },
    echomultimodal: {
        label: 'Echo Multimodal',
        image: <img src={echo} style={imgStyle} alt="placeholder" />,
        value: 'echomultimodal',
    },
    tablet: {
        label: 'Tablet',
        image: <img src={tablet} style={imgStyle} alt="placeholder" />,
        value: 'tablet',
    },
    ereader: {
        label: 'eReader',
        image: <img src={ereader} style={imgStyle} alt="placeholder" />,
        value: 'ereader',
    },
    firetvedition: {
        label: 'FireTV Edition',
        image: <img src={firetv} style={imgStyle} alt="placeholder" />,
        value: 'firetvedition',
    },
    smp: {
        label: 'SMP',
        image: <img src={firetv} style={imgStyle} alt="placeholder" />,
        value: 'smp',
    },
    firetv1p: {
        label: 'FireTV 1P',
        image: <img src={firetv} style={imgStyle} alt="placeholder" />,
        value: 'firetv1p',
    },
    smarthome: {
        label: 'Smart Home',
        image: <img src={smarthome} style={imgStyle} alt="placeholder" />,
        value: 'smarthome',
    },
};

interface ServiceSelectionProps {
    services: StepTwoFormQuery$data['mosaicServiceConfigurationOverview_list']['services'];
}

const ServiceSelection: FC<ServiceSelectionProps> = (props) => {
    const { services, excludeServices } = props;
    const [servSl, setServiceSelection] = useRecoilState(serviceSelection);

    const sortedServices = getSortedServices(services, ServiceOrder).filter(
        (service) => excludeServices.indexOf(service.mosaicServiceId) === -1,
    );

    return (
        <Cards
            onSelectionChange={({ detail }) => {
                setServiceSelection(detail.selectedItems as any);
            }}
            selectedItems={servSl}
            cardDefinition={{
                header: (e) => <Header variant="h2">{e.name}</Header>,
                sections: [
                    {
                        id: 'description',
                        header: 'Description',
                        content: (e) => e.description,
                    },
                ],
            }}
            cardsPerRow={[{ cards: 1 }, { minWidth: 500, cards: 2 }]}
            items={sortedServices}
            selectionType="multi"
            trackBy="mosaicServiceId"
            visibleSections={['name', 'description']}
        />
    );
};

export const StepTwoForm = (props: Props) => {
    const [template, setTemplate] = useRecoilState(selectedTemplate);
    const [servSel, setServiceSel] = useRecoilState(serviceSelection);
    const prodDef = useRecoilValue(productDefinition);
    const [templates, setTemplates] = useState([]);
    const [nextWasClicked, setNextWasClicked] = useState(false);
    const alertRef = useRef(null);

    useEffect(() => {
        let tempForProduct = ProductLineTemplates[prodDef.productLine].map((templateId) => Templates[templateId]);

        tempForProduct = tempForProduct.length > 0 ? tempForProduct : [Templates['basic']];

        // If selected template isn't present in the list of possible templates then select a new one
        if (
            template === '' ||
            typeof tempForProduct.find((t) => t.value === Templates[template].value) === 'undefined'
        ) {
            setTemplate(tempForProduct[0].value);
            setTemplateSelection(tempForProduct[0].value, true);
        }
        setTemplates(tempForProduct);
    }, [prodDef]);

    const { mosaicServiceConfigurationOverview_list: servResp } = useLazyLoadQuery<StepTwoFormQueryInterface>(
        StepTwoFormQuery,
        {},
    );

    const handleCancel = () => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    const handleSubmit = (e: SyntheticEvent) => {
        e.preventDefault();
        if (props.onSubmit) {
            props.onSubmit();
        }
    };

    const setTemplateSelection = (template, override = false) => {
        if ((servSel.length > 0 && !override) || template === '') return;

        const templSel = TemplateMappings[template]?.selected || [];
        const sel = servResp.services.filter((serv) => templSel.indexOf(serv.mosaicServiceId) > -1);
        setServiceSel(sel);
    };

    const onNextClicked = (): boolean => {
        setNextWasClicked(true);
        if (servSel.length > 0) {
            return true;
        }
        alertRef.current.scrollIntoView({ block: 'center' });
        return false;
    };

    useEffect(() => {
        props.giveState ? props.giveState(servSel) : null;
        props.setNextClickHandler ? props.setNextClickHandler(onNextClicked) : null;
    }, [servSel]);

    const handleTemplateChange = ({ detail }: { detail: TilesProps.ChangeDetail }) => {
        setTemplate(detail.value);
        setTemplateSelection(detail.value, true);
    };

    const excludeServices = TemplateMappings[template]?.exclude || [];

    return (
        <form onSubmit={handleSubmit}>
            <Form>
                <Container header={<Header variant="h2">Templates and services</Header>}>
                    <SpaceBetween direction="vertical" size="l">
                        <Header variant="h3" description={<React.Fragment></React.Fragment>}>
                            Template
                        </Header>
                        <Tiles onChange={handleTemplateChange} value={template} columns={4} items={templates} />
                        <Header
                            variant="h3"
                            description={
                                <React.Fragment>
                                    This is a pre-selected list of service offerings based on the template you selected.
                                    You can select or de-select the service offerings based on the needs of the program.
                                    You will be able to configure the service offerings in step 3.
                                </React.Fragment>
                            }
                            info={
                                <Link
                                    onFollow={() => {
                                        setTemplateSelection(template, true);
                                    }}
                                >
                                    Reset
                                </Link>
                            }
                        >
                            Service offerings
                        </Header>

                        <div ref={alertRef}>
                            <Alert
                                visible={nextWasClicked && servSel.length == 0}
                                dismissAriaLabel="Close alert"
                                type="error"
                            >
                                You must select at least one service offering.
                            </Alert>
                        </div>

                        <ServiceSelection services={servResp.services} excludeServices={excludeServices} />
                    </SpaceBetween>
                </Container>
            </Form>
        </form>
    );
};

export const StepTwoReview = () => {
    const selTemplate = useRecoilValue(selectedTemplate);
    const selServices = useRecoilValue(serviceSelection);
    const templateMapping = Templates[selTemplate];

    const sortedServices = getSortedServices(selServices, ServiceOrder);

    return (
        <Container header={<Header variant="h2">Templates and Services</Header>}>
            <ColumnLayout columns={2} variant="text-grid">
                <SpaceBetween size="l">
                    <ValueWithLabel key={1} label="Template">
                        {templateMapping.label}
                    </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="l">
                    <ValueWithLabel key={2} label="Services">
                        {sortedServices.map((s, i) => (
                            <div key={i}>{s.name}</div>
                        ))}
                    </ValueWithLabel>
                </SpaceBetween>
            </ColumnLayout>
        </Container>
    );
};
