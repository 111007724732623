import { Navigate, Route, Routes } from 'react-router-dom';
import CreateContent from "./workspaces/Create";
import DetailsContent from "./workspaces/Details";
import { AppLayout } from "@amzn/awsui-components-react";
import TasksPane from "./workspaces/TasksPane";
import AssociatedServicesPane from "./workspaces/AssociatedServicesPane";
import LandingPageContent from "./landing-page/LandingPage";

const Router = () => {
    return (
        <AppLayout
            headerSelector="mosaic-navigation"
            content={
                <Routes>
                    <Route path='/' element={<LandingPageContent />} />
                    <Route path='/products/create' element={<CreateContent/>} />
                    <Route path="/products/:productId" element={<Navigate to="./tasks" />} />
                    <Route path='/products/:productId' element={<DetailsContent/>} >
                        <Route path="tasks" element={<TasksPane/>} />
                        <Route path="services" element={<AssociatedServicesPane />} />
                        <Route path="*" element={<TasksPane />} />
                    </Route>
                    <Route path="*" element={<h1>404 😮</h1>}/>
                </Routes>
            }
            navigationHide={true}
            toolsHide={true}
            navigation={<></>}
        />
    );
}
export default Router;