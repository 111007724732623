import { atom } from 'recoil';

export const productDefinition = atom({
    key: 'productDefinition',
    default: {
        name: '',
        productLine: '',
        description: '',
        ltpm: '',
        streetDate: '',
        confluenceUrl: '',
        createdAt: new Date().toISOString(),
    },
});

export const serviceSelection = atom({
    key: 'serviceSelection',
    default: [],
});

export const selectedTemplate = atom({
    key: 'selectedTemplate',
    default: '',
})

export const serviceValuesAtom = atom({
    key: 'serviceValues',
    default: {},
});
