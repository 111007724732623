import {RecoilRoot,} from "recoil";
import {RecoilRelayEnvironmentProvider} from "recoil-relay";
import Auth from "./auth/Auth";
import {RelayEnvironmentKey} from './providers/relayEnvironment';
import {Suspense} from "react";
import RelayEnvironment from "./providers/relayEnvironment";
import './helpers/logger';
import {BrowserRouter} from "react-router-dom";
import Router from "./Router";


export const App = () => {
    return (
        <RecoilRoot>
            <RecoilRelayEnvironmentProvider
                environment={RelayEnvironment}
                environmentKey={RelayEnvironmentKey}>
                <Auth>
                    <Suspense fallback={"Loading..."}>
                        <BrowserRouter>
                            <Router/>
                        </BrowserRouter>
                    </Suspense>
                </Auth>
            </RecoilRelayEnvironmentProvider>
        </RecoilRoot>
    );
}