import { useEffect, useMemo, useReducer } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import {
    BreadcrumbGroup,
    ButtonDropdown,
    ColumnLayout,
    Container,
    Flashbar,
    Header,
    SpaceBetween,
} from '@amzn/awsui-components-react';
import DetailsTabs from './DetailsTabs';
import { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar/interfaces';
import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useSubscription } from 'react-relay';
import { DetailsQuery as DetailsQueryType } from './__generated__/DetailsQuery.graphql';
import { ValueWithLabel, ValueWithLabelUrl } from '../common/ValueWithLabel';
import * as React from 'react';
import { DetailsSubscription$variables } from './__generated__/DetailsSubscription.graphql';
import { ulid } from 'ulid';

const subscriptionGraphql = graphql`
    subscription DetailsSubscription($name: String!) {
        subscribe(name: $name) {
            name
            data
        }
    }
`;

interface IProvisionMessageProps {
    productId: string;
}

const provisionMessageReducer = (state, action) => {
    switch (action.type) {
        case `add`:
            return { items: [...state.items, action.item] };
        case `remove`:
            return { items: state.items.filter((item) => item.id !== action.item.id) };
        case 'clear':
            return { items: [] };
        default:
            throw new Error('Invalid action provided to dispatcher');
    }
};

const ProvisioningMessage = (props: IProvisionMessageProps) => {
    const provisioningMessage = {
        type: 'success' as FlashbarProps.Type,
        dismissible: false,
        loading: true,
        header: 'Provisioning in progress',
        content: <>We are provisioning your new product. It may take several minutes.</>,
        id: 'provisioning',
    };
    const successMessage = {
        type: 'success' as FlashbarProps.Type,
        dismissible: true,
        loading: false,
        header: 'Product created successfully!',
        onDismiss: () => {
            itemsDispatch({ action: 'reset' });
        },
        content: <>Your new product is now fully created. New tasks will automatically populate.</>,
        id: 'success',
    };
    const updateMessage = {
        type: 'info' as FlashbarProps.Type,
        dismissible: true,
        loading: false,
        header: 'Provisioning status updated!',
    };

    const [items, itemsDispatch] = useReducer(provisionMessageReducer, { items: [] });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const addUpdateItem = (message) => {
        const newItem = {
            ...updateMessage,
            content: <>{message}</>,
            id: ulid(),
            onDismiss: () => itemsDispatch({ type: 'remove', item: newItem }),
        };
        itemsDispatch({ type: 'add', item: newItem });
    };

    function useProvisionUpdateSubscription(input: DetailsSubscription$variables) {
        const config = useMemo(() => {
            return {
                subscription: subscriptionGraphql,
                variables: input,
                onNext: (next) => {
                    console.log('Next: ', next);
                    const msgJson = JSON.parse(next.subscribe.data);
                    const update = msgJson?.message ?? 'Failed to parse update';
                    addUpdateItem(update);
                },
            };
        }, [input]);

        return useSubscription(config);
    }

    useProvisionUpdateSubscription({ name: props.productId });

    return <Flashbar items={items.items} />;
};

export const DetailsQuery = graphql`
    query DetailsQuery($productId: ID!) {
        product_getById(productId: $productId) {
            productId
            name
            description
            definition {
                deviceName
                productLine
                tentativeStreetDate
                confluenceUrl
            }
            subscribers {
                identity
            }
        }
    }
`;

const DetailsContent = () => {
    const { productId } = useParams();
    const prodDef = useLazyLoadQuery<DetailsQueryType>(DetailsQuery, {
        productId,
    });

    let ltpm_string = '';
    if (prodDef.product_getById.subscribers) {
        ltpm_string = prodDef.product_getById.subscribers
            .map((element) => element.identity)
            .filter((element) => element !== '')
            .join(',');
    }

    const urlLink = prodDef.product_getById.definition.confluenceUrl
        ? `${prodDef.product_getById.definition.confluenceUrl}`
        : '';

    return (
        <>
            <SpaceBetween direction="vertical" size="l">
                <ProvisioningMessage productId={productId} />
                <BreadcrumbGroup
                    items={[
                        { text: 'Products', href: '/' },
                        {
                            text: `${prodDef.product_getById.name}`,
                            href: `/products/${prodDef.product_getById.productId}`,
                        },
                    ]}
                    ariaLabel="Breadcrumbs"
                />
                <Header variant="h1">{prodDef.product_getById.name}</Header>

                <Container header={<Header variant="h2">Information</Header>}>
                    <ColumnLayout columns={2} variant="text-grid">
                        <SpaceBetween size="l">
                            <ValueWithLabel label="Program code name">{prodDef.product_getById.name}</ValueWithLabel>
                            <ValueWithLabel label="Product line">
                                {prodDef.product_getById.definition.productLine}
                            </ValueWithLabel>
                            <ValueWithLabel label="LTPM">{ltpm_string}</ValueWithLabel>
                        </SpaceBetween>
                        <SpaceBetween size="l">
                            <ValueWithLabel label="Brief description">
                                {prodDef.product_getById.description}
                            </ValueWithLabel>
                            <ValueWithLabel label="Street date">
                                {prodDef.product_getById.definition.tentativeStreetDate}
                            </ValueWithLabel>
                            <ValueWithLabelUrl label="Confluence URL" url={urlLink}>
                                {prodDef.product_getById.definition.confluenceUrl}
                            </ValueWithLabelUrl>
                        </SpaceBetween>
                    </ColumnLayout>
                </Container>
            </SpaceBetween>
        </>
    );
};

export default DetailsContent;
