export const enum Environment {
    beta = 'beta',
    prod = 'prod',
    test = 'test',
}
const API_MAPPING: { [key: string]: Environment } = {
    'landing-test-matgeyer.beta.mosaic.devices.amazon.dev': Environment.test,
    localhost: Environment.test,
    'beta.mosaic.devices.amazon.dev': Environment.beta,
    'mosaic.devices.amazon.dev': Environment.prod,
    'landing.mosaic.devices.amazon.dev': Environment.prod,
};
const DEFAULT_STAGE = Environment.beta;

export const getEnvironment = (): Environment => {
    const currentHostName = window.location.hostname;
    return API_MAPPING[currentHostName] || DEFAULT_STAGE;
};
