import { Box } from '@amzn/awsui-components-react';
import { FC } from 'react';
import * as React from 'react';

interface Props {
    label: string;
    required?: boolean;
}

export const ValueWithLabel: FC<Props> = ({ label, children, required }) => (
    <div>
        <Box variant="awsui-key-label">
            {label} {required || required === undefined ? '' : <i>- optional</i>}
        </Box>
        <div>{children || '-'}</div>
    </div>
);

export const ValueWithLabelUrl: FC<Props> = ({ label, url, children, required }) => (
    <div>
        <Box variant="awsui-key-label">
            {label} {required || required === undefined ? '' : <i>- optional</i>}
        </Box>
        <a href={url} target="_blank" rel="noopener noreferrer">
            <div>{children || ''}</div>
        </a>
    </div>
);
