import { FC, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { user } from './store';
import { Amplify } from 'aws-amplify';
import { AmplifyConfig, authenticate } from './authenticate';

const Auth: FC = ({ children }) => {
    const [usr, setUser] = useRecoilState(user);
    useEffect(() => {
        new Promise((res) => {
            Amplify.configure(AmplifyConfig);
            res(null);
        })
            .then(authenticate)
            .then(setUser);
    }, []);

    if (!usr) {
        return <></>;
    }
    return <>{children}</>;
};

export default Auth;
