import { IFieldProps } from './ServiceRenderer';
import { FormField, Multiselect } from '@amzn/awsui-components-react';
import { FieldLabel } from './FieldLabel';
import { FC, useMemo } from 'react';

const MultiSelectField: FC<IFieldProps> = (props) => {
    const { field, service, fieldValidationContext, onChange } = props;
    const path = useMemo(() => {
        return [service.mosaicServiceId, field.handle];
    }, [service, field]);

    const [opts, labelMap] = useMemo(() => {
        const opts = field.options.map((opt) => ({ value: opt.value, label: opt.name }));
        const labelMap = field.options.reduce((accum, opt) => {
            accum[opt.value] = opt.name;
            return accum;
        }, {});
        return [opts, labelMap];
    }, [field]);

    const handleChange = ({ detail }) => {
        if (onChange) {
            onChange(path, detail.selectedOptions.map((opt) => opt.value).join(','));
        }
    };

    const value = fieldValidationContext?.value || '';
    const error = fieldValidationContext?.error || '';

    const getValue = () => {
        return (
            value
                .split(',')
                .filter((v) => v !== '')
                .map((v) => ({ value: v, label: labelMap[v] })) || []
        );
    };

    return (
        <FormField
            key={field.name}
            label={<FieldLabel label={field.name} required={field.required} />}
            description={field.description}
            errorText={error}
        >
            <Multiselect
                virtualScroll
                selectedOptions={getValue()}
                onChange={handleChange}
                deselectAriaLabel={(e) => `Remove ${e.label}`}
                options={opts}
            ></Multiselect>
        </FormField>
    );
};

export default MultiSelectField;
