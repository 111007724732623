import { FormField, RadioGroup } from '@amzn/awsui-components-react';
import * as React from 'react';
import { IFieldProps } from './ServiceRenderer';
import { FieldLabel } from './FieldLabel';
import { FC, useMemo } from 'react';

const RadioButtonsField: FC<IFieldProps> = (props) => {
    const { field, service, fieldValidationContext, onChange } = props;
    const path = useMemo(() => {
        return [service.mosaicServiceId, field.handle];
    }, [service, field]);

    const value = fieldValidationContext?.value || '';
    const error = fieldValidationContext?.error || '';
    const handleChange = ({ detail }) => {
        if (onChange) {
            onChange(path, detail.value);
        }
    };
    return (
        <FormField
            key={field.name}
            label={<FieldLabel key={field.name} label={field.name} required={field.required} />}
            description={field.description}
            errorText={error}
        >
            <RadioGroup
                onChange={handleChange}
                value={value}
                items={field.options.map((opt) => ({ label: opt.name, value: opt.name }))}
            />
        </FormField>
    );
};

export default RadioButtonsField;
