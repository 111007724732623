import '@amzn/awsui-global-styles/polaris.css';

import React from 'react';
import ReactDOM from 'react-dom';
import {App} from "./App";

const loaded = localStorage.getItem('mosaic.loaded');
if (loaded === 'true') {
    ReactDOM.render(<App/>,
        document.getElementById('root'));
} else {
    const elem = document.getElementById('mosaic-navigation');
    const handleLoadEvent = (e) => {
        ReactDOM.render(<App/>,
            document.getElementById('root'));
        elem.removeEventListener('mosaic.loaded', handleLoadEvent);
    };
    elem.addEventListener('mosaic.loaded', handleLoadEvent);
}