/**
 * @generated SignedSource<<a6021df4517d4e4d2e738070d5193c15>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SetProductInput = {
  configuration?: any | null;
  definition?: ProductDefinitionInput | null;
  description: string;
  name: string;
  subscribers?: ReadonlyArray<SubscriberInput> | null;
};
export type ProductDefinitionInput = {
  confluenceUrl: string;
  deviceName: string;
  productLine: string;
  tentativeStreetDate: any;
};
export type SubscriberInput = {
  actorType?: string | null;
  identity: string;
};
export type CreateMutation$variables = {
  input: SetProductInput;
};
export type CreateMutation$data = {
  readonly product_set: {
    readonly productId: string;
  };
};
export type CreateMutation = {
  response: CreateMutation$data;
  variables: CreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ProductResponse",
    "kind": "LinkedField",
    "name": "product_set",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "productId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "27b0486ae005477e929c4ab3c77a8d68",
    "id": null,
    "metadata": {},
    "name": "CreateMutation",
    "operationKind": "mutation",
    "text": "mutation CreateMutation(\n  $input: SetProductInput!\n) {\n  product_set(input: $input) {\n    productId\n  }\n}\n"
  }
};
})();

(node as any).hash = "fac68400677401c494ecab190c6c80ce";

export default node;
