/**
 * @generated SignedSource<<e33b8de1916d8ac1ddf928c4a82109f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LandingPageTableQuery$variables = {};
export type LandingPageTableQuery$data = {
  readonly product_list: ReadonlyArray<{
    readonly definition: {
      readonly deviceName: string;
      readonly productLine: string;
    };
    readonly description: string;
    readonly name: string;
    readonly productId: string;
  }>;
};
export type LandingPageTableQuery = {
  response: LandingPageTableQuery$data;
  variables: LandingPageTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ProductResponse",
    "kind": "LinkedField",
    "name": "product_list",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "productId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductDefinition",
        "kind": "LinkedField",
        "name": "definition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deviceName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "productLine",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LandingPageTableQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LandingPageTableQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "7e3b846d9fd2d186af0bacb9ca6cbad3",
    "id": null,
    "metadata": {},
    "name": "LandingPageTableQuery",
    "operationKind": "query",
    "text": "query LandingPageTableQuery {\n  product_list {\n    productId\n    name\n    description\n    definition {\n      deviceName\n      productLine\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a388a7fee8d1755cd7c5420cfe1b11c4";

export default node;
