import { SpaceBetween } from '@amzn/awsui-components-react';
import { StepThreeReview } from '../wizard/StepThreeForm';
import { StepTwoReview } from '../wizard/StepTwoForm';

const AssociatedServicesPane = () => {
    return (
        <SpaceBetween direction="vertical" size="l">
            <StepTwoReview />
            <StepThreeReview />
        </SpaceBetween>
    );
};

export default AssociatedServicesPane;
