import {
    Button,
    CollectionPreferences,
    Header,
    Link,
    Pagination,
    SpaceBetween,
    Table,
    TextFilter,
} from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import EmptyTable from '../workspaces/EmptyTable';
import { format } from 'date-fns';
import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';
import { LandingPageTableQuery as LandingPageTableQueryType } from './__generated__/LandingPageTableQuery.graphql';

enum AccessRights {
    EDIT = 'Edit',
    VIEW_ONLY = 'View Only',
}

const LandingPageTableQuery = graphql`
    query LandingPageTableQuery {
        product_list {
            productId
            name
            description
            definition {
                deviceName
                productLine
            }
        }
    }
`;

const LandingPageTable = () => {
    const [preferences, setPreferences] = useState({
        pageSize: 10,
        visibleContent: ['deviceName', 'productLine', 'lastVisited', 'accessRights'],
    } as any);

    const query = useLazyLoadQuery<LandingPageTableQueryType>(LandingPageTableQuery, {});

    const formatDate = (date: Date) => {
        // ex. 2022-07-07 19:23:11
        return format(date, 'yyyy-MM-dd HH:mm:ss');
    };

    const productList = query.product_list;

    const columnDefinitions = [
        {
            id: 'deviceName',
            header: 'Device Name',
            cell: (item) => (
                <Link href={`/products/${item.productId}`} variant="secondary">
                    {item.name}
                </Link>
            ),
            sortingField: 'deviceName',
        },
        {
            id: 'productLine',
            header: 'Product Line',
            cell: (item) => item.definition.productLine,
            sortingField: 'productLine',
        },
        {
            id: 'description',
            header: 'Description',
            cell: (item) => item.description,
            sortingField: 'description',
        },
    ];

    const collectionPreferencesProps = {
        pageSizePreference: {
            title: 'Select page size',
            options: [
                { value: 10, label: '10 products' },
                { value: 20, label: '20 products' },
                { value: 50, label: '50 products' },
            ],
        },
        visibleContentPreference: {
            title: 'Select visible columns',
            options: [
                {
                    label: 'Workspace properties',
                    options: [
                        { id: 'name', label: 'Name', editable: false },
                        { id: 'productLine', label: 'Product Line' },
                        { id: 'description', label: 'Description' },
                    ],
                },
            ],
        },
    };

    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        productList,
        {
            filtering: {
                empty: (
                    <EmptyTable
                        title="No products"
                        subtitle="No products to display"
                        action={<Button href="/products/create">Create Workspace</Button>}
                    />
                ),
                noMatch: (
                    <EmptyTable
                        title="No matches"
                        subtitle="No matching products found"
                        action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                    />
                ),
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: {},
            selection: {},
        },
    );

    const { selectedItems } = collectionProps;

    const getMatchesCountText = (filteredItemsCount: number) => {
        if (filteredItemsCount === 1) {
            return `${filteredItemsCount} matching product found`;
        }
        return `${filteredItemsCount} matching products found`;
    };

    return (
        <Table
            {...collectionProps}
            header={
                <Header
                    variant="h2"
                    counter={
                        selectedItems.length
                            ? `(${selectedItems.length}/${productList.length})`
                            : `(${productList.length})`
                    }
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="primary" href="/products/create">
                                Create Product
                            </Button>
                        </SpaceBetween>
                    }
                >
                    Products
                </Header>
            }
            columnDefinitions={columnDefinitions}
            visibleColumns={preferences.visibleContent}
            items={items}
            loading={false}
            loadingText="Loading products"
            pagination={
                <Pagination
                    {...paginationProps}
                    ariaLabels={{
                        nextPageLabel: 'Next page',
                        previousPageLabel: 'Previous page',
                        pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
                    }}
                />
            }
            filter={
                <TextFilter
                    {...filterProps}
                    countText={getMatchesCountText(filteredItemsCount)}
                    filteringPlaceholder="Find products"
                    filteringAriaLabel="Filter products"
                />
            }
            preferences={
                <CollectionPreferences
                    {...collectionPreferencesProps}
                    preferences={preferences}
                    cancelLabel="Cancel"
                    confirmLabel="Confirm"
                    title="Preferences"
                    onConfirm={({ detail }) => setPreferences(detail)}
                />
            }
        />
    );
};

export default LandingPageTable;
