import { getToken } from '../auth/authenticate';
import { getAPIUrl } from '../configuration/graphql';

const fetchGraphQL = async (query: string, variables?: any) => {
    // Fetch data from GitHub's GraphQL API:
    const response = await fetch(getAPIUrl(), {
        method: 'POST',
        headers: {
            Authorization: `${await getToken()}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query,
            variables,
        }),
    });

    // Get the response as JSON
    return await response.json();
};

export default fetchGraphQL;
