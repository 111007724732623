import { StepTwoFormQuery$data } from './__generated__/StepTwoFormQuery.graphql';

export const getSortedServices = (
    services: StepTwoFormQuery$data['mosaicServiceConfigurationOverview_list']['services'],
    order: string[],
) => {
    const servMap = services.reduce((accum, serv) => {
        accum[serv.mosaicServiceId] = serv;
        return accum;
    }, {});
    return order.filter((s) => !!servMap[s]).map((s) => servMap[s]);
};
