/**
 * @generated SignedSource<<4175c27c246b6811c9349720cda564e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type StepTwoFormQuery$variables = {};
export type StepTwoFormQuery$data = {
  readonly mosaicServiceConfigurationOverview_list: {
    readonly count: number;
    readonly limit: number;
    readonly offset: number;
    readonly services: ReadonlyArray<{
      readonly description: string | null;
      readonly infoUrl: string | null;
      readonly mosaicServiceId: string;
      readonly name: string;
      readonly serviceDependencyIds: ReadonlyArray<string> | null;
    }>;
    readonly total: number;
  };
};
export type StepTwoFormQuery = {
  response: StepTwoFormQuery$data;
  variables: StepTwoFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MosaicServiceConfigurationOverviewResponse",
    "kind": "LinkedField",
    "name": "mosaicServiceConfigurationOverview_list",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "limit",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "offset",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "total",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MosaicServiceConfigurationOverview",
        "kind": "LinkedField",
        "name": "services",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mosaicServiceId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "serviceDependencyIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "infoUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StepTwoFormQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "StepTwoFormQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "f7981ea826173e713ba25c3882bc5b6f",
    "id": null,
    "metadata": {},
    "name": "StepTwoFormQuery",
    "operationKind": "query",
    "text": "query StepTwoFormQuery {\n  mosaicServiceConfigurationOverview_list {\n    count\n    limit\n    offset\n    total\n    services {\n      mosaicServiceId\n      serviceDependencyIds\n      name\n      description\n      infoUrl\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9bd1c81d98b50fb0008158fab6ce7b53";

export default node;
