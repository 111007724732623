import { IFieldProps } from './ServiceRenderer';
import { Checkbox, FormField } from '@amzn/awsui-components-react';
import { FieldLabel } from './FieldLabel';
import { FC, useMemo } from 'react';

const CheckboxListField: FC<IFieldProps> = (props) => {
    const { field, service, fieldValidationContext, onChange } = props;
    const path = useMemo(() => {
        return [service.mosaicServiceId, field.handle];
    }, [service, field]);

    const value = fieldValidationContext?.value || '';
    const error = fieldValidationContext?.error || '';

    const getValue = (opt) => {
        return value.split(',').filter((o) => o === opt.name).length > 0;
    };

    const selectedOptions = field.options.reduce((accum, opt) => {
        accum[opt.handle] = getValue(opt);
        return accum;
    }, {});

    const handleChange = (detail, opt) => {
        if (!onChange) {
            return;
        }

        selectedOptions[opt.handle] = detail.checked;

        const newValue = field.options
            .filter((o) => selectedOptions[o.handle])
            .map((o) => o.name)
            .join(',');
        onChange(path, newValue);
    };

    return (
        <FormField
            key={field.name}
            label={<FieldLabel label={field.name} required={field.required} />}
            description={field.description}
            errorText={error}
        >
            {field.options.map((opt) => (
                <Checkbox key={opt.name} onChange={({ detail }) => handleChange(detail, opt)} checked={getValue(opt)}>
                    {opt.name}
                </Checkbox>
            ))}
        </FormField>
    );
};

export default CheckboxListField;
