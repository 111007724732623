import { Auth } from 'aws-amplify';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { getCognitoConf } from '../configuration/cognito';
import { getAPIUrl } from '../configuration/graphql';

const refreshSession = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    return new Promise((res, rej) => {
        cognitoUser.refreshSession(
            currentSession.getRefreshToken(),
            (err: Error, cognitoUserSession: CognitoUserSession) => {
                const token = cognitoUserSession.getIdToken();
                res({
                    token: token.getJwtToken(),
                    expires_at: token.getExpiration(),
                });
            },
        );
    });
};

const authenticateUser = async () => {
    return Auth.federatedSignIn({ customProvider: 'AmazonFederate' });
};

export const getToken = async () => {
    return (await Auth.currentSession()).getIdToken().getJwtToken();
};

export const authenticate = async (): Promise<CognitoUser> => {
    try {
        return await Auth.currentAuthenticatedUser();
    } catch (err) {
        await authenticateUser();
        return await Auth.currentAuthenticatedUser();
    }
};

const cognitoConf = getCognitoConf();

export const AmplifyConfig = {
    aws_appsync_graphqlEndpoint: getAPIUrl(),
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    Auth: {
        mandatorySignId: true,
        region: cognitoConf.REGION,
        userPoolId: cognitoConf.USER_POOL_ID,
        userPoolWebClientId: cognitoConf.APP_CLIENT_ID,
    },
    oauth: {
        domain: `${cognitoConf.SUB_DOMAIN}.auth.${cognitoConf.REGION}.amazoncognito.com`, // check domain name under app integration
        redirectSignIn: cognitoConf.REDIRECT, // this should be added in the appclient settings as well
        redirectSignOut: cognitoConf.REDIRECT,
        scope: ['openid'], // important
        responseType: 'code',
    },
};
