import * as React from 'react';
import {
    Header,
    Container,
    Form,
    SpaceBetween,
    FormField,
    Input,
    Link,
    ColumnLayout,
    Select,
} from '@amzn/awsui-components-react';
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { productDefinition } from './store';
import DateInput from '@amzn/awsui-components-react/polaris/date-picker';
import { FieldLabel } from './FieldLabel';
import { ValueWithLabel } from '../common/ValueWithLabel';
import isAlpha from 'validator/lib/isAlpha';
import isEmpty from 'validator/lib/isEmpty';
import isLowercase from 'validator/lib/isLowercase';
import isLength from 'validator/lib/isLength';

interface Props {
    onCancel?: () => void;
    onCreateAndExit?: () => void;
    onSubmit?: () => void;
    giveState?: (state: any) => void;
    setNextClickHandler?: (handler: () => boolean) => void;
}

const productLineOptions = [
    {
        label: 'Echo',
        value: 'Echo',
    },
    {
        label: 'e-Reader',
        value: 'e-Reader',
    },
    {
        label: 'FireTV',
        value: 'FireTV',
    },
    {
        label: 'Fire TV Edition',
        value: 'Fire TV Edition',
    },
    {
        label: 'Tablet',
        value: 'Tablet',
    },
    {
        label: 'Health & Wellness',
        value: 'Health & Wellness',
    },
    {
        label: 'Smart Home',
        value: 'Smart Home',
    },
    {
        label: 'CoRo',
        value: 'CoRo',
    },
    {
        label: 'Ring',
        value: 'Ring',
    },
    {
        label: 'Blink',
        value: 'Blink',
    },
    {
        label: 'eero',
        value: 'eero',
    },
];
const deviceCategoryOptions = [
    {
        label: 'Stick',
        value: 'Stick',
    },
    {
        label: 'Cube',
        value: 'Cube',
    },
    {
        label: 'TV',
        value: 'TV',
    },
];

export const StepOneForm = (props: Props) => {
    const [prodDef, setProdDef] = useRecoilState(productDefinition);

    const [name, setName] = useState(prodDef.name);
    const [description, setDescription] = useState(prodDef.description);
    const [productLine, setProductLine] = useState(prodDef.productLine);
    const [ltpm, setLTPM] = useState(prodDef.ltpm);
    const [streetDate, setStreetDate] = useState(prodDef.streetDate);
    const [confluenceUrl, setConfluenceUrl] = useState(prodDef.confluenceUrl);

    const [validationErrors, setValidationErrors] = useState({
        name: '',
        description: '',
        productLine: '',
        ltpm: '',
        streetDate: '',
        confluenceUrl: '',
    });

    const [nextWasClicked, setNextWasClicked] = useState(false);

    const handleCancel = () => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    const handleOnCreateAndExit = () => {
        if (props.onCreateAndExit) {
            props.onCreateAndExit();
        }
    };

    const getInputErrors = () => {
        return {
            name: validateDeviceName(),
            productLine: validateProductLine(),
            description: validateDescription(),
            ltpm: validateLtpm(),
            streetDate: validateStreetDate(),
            confluenceUrl: validateConfluenceUrl(),
        };
    };

    const validateProductLine = (): string => {
        return isEmpty(productLine) ? 'You must select a Product Line.' : '';
    };

    const validateDescription = (): string => {
        return isEmpty(description) ? 'You must enter a description.' : '';
    };

    const validateStreetDate = (): string => {
        return isEmpty(streetDate) ? 'You must enter a tentative street date.' : '';
    };

    const validateConfluenceUrl = (): string => {
        return isEmpty(confluenceUrl) ? 'You must enter a Confluence NPI Page Link.' : '';
    };

    const validateDeviceName = (): string => {
        if (isEmpty(name)) {
            return 'You must enter a name.';
        }
        if (!isAlpha(name)) {
            return 'Name can only contain upper and lower case letters.';
        }
        return '';
    };

    const validateAlias = (alias: string): string => {
        if (!isLowercase(alias)) {
            return 'Aliases must be lowercase';
        }
        if (!isAlpha(alias)) {
            return 'One or more aliases has an invalid character.';
        }
        if (!isLength(alias, { min: 1, max: 8 })) {
            return 'Aliases must be 1 to 8 characters in length.';
        }
        return '';
    };

    const validateLtpm = (): string => {
        if (isEmpty(ltpm)) {
            return 'You must enter at least one alias.';
        }
        const ltpmParts = ltpm
            .split(';')
            .map((element) => element.trim())
            .filter((element) => element !== '');

        for (let i = 0; i < ltpmParts.length - 1; i++) {
            const error = validateAlias(ltpmParts[i]);
            if (error !== '') {
                // No need to validate any more aliases
                return error;
            }
        }
        // Special case to allow trailing ';' which results in the last item in ltpmParts being an empty string
        const lastAlias = ltpmParts[ltpmParts.length - 1];
        if (lastAlias !== '') {
            return validateAlias(lastAlias);
        }
        return '';
    };

    const onNextClicked = (): boolean => {
        // Indicate that an attempt was made to continue to the next step, enabling real-time validation
        setNextWasClicked(true);
        // Handle validation of all fields and return true only if all are valid
        const errors = getInputErrors();
        setValidationErrors(errors);
        // If none of the properties have error text then the form is valid
        return Object.keys(errors).filter((k) => errors[k] !== '').length == 0;
    };

    const save = () => {
        const nextState = {
            name,
            description,
            productLine,
            ltpm,
            streetDate,
            confluenceUrl,
            createdAt: new Date().toISOString(),
        };
        setProdDef(nextState);
        props.giveState ? props.giveState(nextState) : null;

        // Update the handler that will be called by the wizard on next click to determine if this form is valid
        props.setNextClickHandler ? props.setNextClickHandler(onNextClicked) : null;

        // Validate inputs, but not before the next button has been clicked at least once
        if (!nextWasClicked) {
            return;
        }
        setValidationErrors(getInputErrors());
    };

    useEffect(save, [name, description, productLine, ltpm, streetDate, confluenceUrl]);

    const handleSubmit = (e: SyntheticEvent) => {
        e.preventDefault();
        save();
        if (props.onSubmit) {
            props.onSubmit();
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Form>
                <Container header={<Header variant="h2">Define Product</Header>}>
                    <SpaceBetween direction="vertical" size="l">
                        <FormField
                            label="Device name"
                            description={'Amazon internal code name such as Quartz, Eiger, Hypnos, Pear, etc.'}
                            errorText={validationErrors.name}
                        >
                            <Input value={name} onChange={({ detail }) => setName(detail.value)} placeholder={'Tank'} />
                        </FormField>
                        <FormField
                            label="Product line"
                            description={'Product line that the device belongs to e.g. Echo, FireTV, Tablet, etc.'}
                            errorText={validationErrors.productLine}
                        >
                            <Select
                                selectedOption={productLineOptions.find((opt) => opt.value === productLine)}
                                onChange={({ detail }) => setProductLine(detail.selectedOption.value)}
                                options={productLineOptions}
                            />
                        </FormField>
                        <FormField
                            label="Brief description"
                            description="Description of the device."
                            errorText={validationErrors.description}
                        >
                            <Input
                                value={description}
                                onChange={({ detail }) => setDescription(detail.value)}
                                placeholder={'Add a description of your project'}
                            />
                        </FormField>
                        <FormField
                            label={<FieldLabel label="Launch TPM alias" required={true} />}
                            description="LTPM point of contact. Exclude @amazon.com.  Add multiple aliases separated by semicolon."
                            errorText={validationErrors.ltpm}
                        >
                            <Input
                                value={ltpm}
                                onChange={({ detail }) => setLTPM(detail.value)}
                                placeholder={'joeltros'}
                            />
                        </FormField>
                        <FormField label="Tentative street date" errorText={validationErrors.streetDate}>
                            <DateInput value={streetDate} onChange={({ detail }) => setStreetDate(detail.value)} />
                        </FormField>
                        <FormField
                            label="Confluence NPI page URL"
                            description="Confluence NPI page URL for the Device."
                            errorText={validationErrors.confluenceUrl}
                        >
                            <Input
                                value={confluenceUrl}
                                onChange={({ detail }) => setConfluenceUrl(detail.value)}
                                placeholder={'https://wiki.labcollab.net/confluence/display/PMO/MyDemoProgram'}
                            />
                        </FormField>
                    </SpaceBetween>
                </Container>
            </Form>
        </form>
    );
};

interface StepOneReviewProps {
    title: string;
}

export const StepOneReview: FC<StepOneReviewProps> = (props) => {
    const prodDef = useRecoilValue(productDefinition);

    return (
        <Container header={<Header variant="h2">{props.title}</Header>}>
            <ColumnLayout columns={2} variant="text-grid">
                <SpaceBetween size="l">
                    <ValueWithLabel label="Program code name">{prodDef.name}</ValueWithLabel>
                    <ValueWithLabel label="Product line">{prodDef.productLine}</ValueWithLabel>
                    <ValueWithLabel label="LTPM">{prodDef.ltpm}</ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="l">
                    <ValueWithLabel label="Brief description">{prodDef.description}</ValueWithLabel>
                    <ValueWithLabel label="Street date">{prodDef.streetDate}</ValueWithLabel>
                    <ValueWithLabel label="Confluence URL">{prodDef.confluenceUrl}</ValueWithLabel>
                </SpaceBetween>
            </ColumnLayout>
        </Container>
    );
};
