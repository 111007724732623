/**
 * @generated SignedSource<<0637f8a220c484bbfa2911504b70fc22>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MosaicServiceConfigurationsInput = {
  mosaicServiceIds?: ReadonlyArray<string> | null;
};
export type StepThreeFormQuery$variables = {
  input?: MosaicServiceConfigurationsInput | null;
};
export type StepThreeFormQuery$data = {
  readonly mosaicServiceConfiguration_list: {
    readonly count: number;
    readonly limit: number;
    readonly offset: number;
    readonly services: ReadonlyArray<{
      readonly description: string | null;
      readonly fields: ReadonlyArray<{
        readonly description: string | null;
        readonly handle: string | null;
        readonly name: string;
        readonly options: ReadonlyArray<{
          readonly description: string | null;
          readonly handle: string | null;
          readonly name: string;
          readonly value: string | null;
        }> | null;
        readonly required: boolean | null;
        readonly type: string | null;
      }> | null;
      readonly infoUrl: string | null;
      readonly mosaicServiceId: string;
      readonly name: string;
      readonly serviceDependencyIds: ReadonlyArray<string> | null;
    }>;
    readonly total: number;
  };
};
export type StepThreeFormQuery = {
  response: StepThreeFormQuery$data;
  variables: StepThreeFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MosaicServiceConfigurationResponse",
    "kind": "LinkedField",
    "name": "mosaicServiceConfiguration_list",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "limit",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "offset",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "total",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MosaicServiceConfiguration",
        "kind": "LinkedField",
        "name": "services",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mosaicServiceId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "serviceDependencyIds",
            "storageKey": null
          },
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "infoUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ServiceConfigurationField",
            "kind": "LinkedField",
            "name": "fields",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "required",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ServiceConfigurationFieldOption",
                "kind": "LinkedField",
                "name": "options",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StepThreeFormQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StepThreeFormQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "2624364665f9a3beda037aa65ddbc103",
    "id": null,
    "metadata": {},
    "name": "StepThreeFormQuery",
    "operationKind": "query",
    "text": "query StepThreeFormQuery(\n  $input: MosaicServiceConfigurationsInput\n) {\n  mosaicServiceConfiguration_list(input: $input) {\n    count\n    limit\n    offset\n    total\n    services {\n      mosaicServiceId\n      serviceDependencyIds\n      name\n      description\n      infoUrl\n      fields {\n        name\n        description\n        required\n        handle\n        type\n        options {\n          name\n          description\n          handle\n          value\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2f7bae22a332337e85ca81a257dfe25c";

export default node;
