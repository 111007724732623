/**
 * @generated SignedSource<<953d4bfc520fdb41dcc45f571e3f10f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DetailsQuery$variables = {
  productId: string;
};
export type DetailsQuery$data = {
  readonly product_getById: {
    readonly definition: {
      readonly confluenceUrl: string | null;
      readonly deviceName: string;
      readonly productLine: string;
      readonly tentativeStreetDate: any;
    };
    readonly description: string;
    readonly name: string;
    readonly productId: string;
    readonly subscribers: ReadonlyArray<{
      readonly identity: string;
    } | null> | null;
  } | null;
};
export type DetailsQuery = {
  response: DetailsQuery$data;
  variables: DetailsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "productId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "productId",
        "variableName": "productId"
      }
    ],
    "concreteType": "ProductResponse",
    "kind": "LinkedField",
    "name": "product_getById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "productId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductDefinition",
        "kind": "LinkedField",
        "name": "definition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deviceName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "productLine",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tentativeStreetDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "confluenceUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SubscriberDefinition",
        "kind": "LinkedField",
        "name": "subscribers",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "identity",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DetailsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DetailsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "dc4d142ddc0ac5c0f7b122cdff32fb56",
    "id": null,
    "metadata": {},
    "name": "DetailsQuery",
    "operationKind": "query",
    "text": "query DetailsQuery(\n  $productId: ID!\n) {\n  product_getById(productId: $productId) {\n    productId\n    name\n    description\n    definition {\n      deviceName\n      productLine\n      tentativeStreetDate\n      confluenceUrl\n    }\n    subscribers {\n      identity\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9aa55da1099bd02a62b07961e1f702b2";

export default node;
