import * as React from 'react';
import { SyntheticEvent } from 'react';
import { StepTwoReview } from './StepTwoForm';
import { StepThreeReview } from './StepThreeForm';
import { Button, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { StepOneReview } from './StepOneForm';
import { Environment, getEnvironment } from '../configuration/environment';

interface Props {
    onCancel?: () => void;
    onCreateAndExit?: () => void;
    onSubmit?: () => void;
    giveState?: (state: any) => void;
    setActiveStepIndex: (index: number) => void;
}

export const StepFourForm = (props: Props) => {
    const handleCancel = () => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    const handleSubmit = (e: SyntheticEvent) => {
        e.preventDefault();
        if (props.onSubmit) {
            props.onSubmit();
        }
    };

    const environment = getEnvironment();

    return (
        <SpaceBetween direction="vertical" size="l">
            <Header key={1} variant="h3" actions={<Button onClick={() => props.setActiveStepIndex(0)}>Edit</Button>}>
                Step 1: Define Product
            </Header>
            <StepOneReview key={'StepOneReview'} title={'Define Product'} />

            {environment !== Environment.prod && (
                <>
                    <Header
                        key={2}
                        variant="h3"
                        actions={<Button onClick={() => props.setActiveStepIndex(1)}>Edit</Button>}
                    >
                        Step 2: Select Template
                    </Header>
                    <StepTwoReview key={'StepTwoReview'} />
                    <Header
                        key={3}
                        variant="h3"
                        actions={<Button onClick={() => props.setActiveStepIndex(2)}>Edit</Button>}
                    >
                        Step 3: Configure Inputs
                    </Header>
                    <StepThreeReview key={'StepThreeReview'} />
                </>
            )}
        </SpaceBetween>
    );
};
