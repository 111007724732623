// based on Beacon wizard:
// https://code.amazon.com/packages/StarforgeUserWebsite/blobs/a75c4ca3c25384150c64f10b3675e2788bead7a4/--/src/components/wizard/wizard.tsx

import Wizard, { WizardProps } from '@amzn/awsui-components-react/polaris/wizard';
import { RecoilValueReadOnly } from 'recoil';
import { useState } from 'react';

interface WizardState {
    isLoading: boolean;
    canAdvance: boolean | RecoilValueReadOnly<boolean>;
    stepStates: { [key: number]: any };
    inError: boolean;
}

interface WizardStepInput<T> {
    state: T;
    giveState: (newState: T) => void;
    editMode: boolean;
    inError: boolean;
    stepStates: { [key: number]: any };
    index: number;
    setNextClickHandler: (handler: () => boolean) => void;
}

interface WizardStepProps {
    title: string;
    description?: string;
}

export interface WizardStep<T> {
    component: (props: WizardStepInput<T>) => JSX.Element;
    props: WizardStepProps;
    isLoading: (state: T | undefined) => boolean;
    handleNext?: () => boolean;
}

export interface WizardConfig {
    steps: WizardStep<any>[];
    editMode: boolean;
    submit: string;
    onSubmit: (state: any) => void;
    onCancel: () => void;
    startingState?: any[];
    forceLoading?: boolean;
    activeStepIndex: number;
    setActiveStepIndex: (ind: number) => void;
}

export default (props: WizardConfig) => {
    const { activeStepIndex, setActiveStepIndex } = props;
    const [wizardState, setWizardState]: [WizardState, any] = useState({
        inError: false,
        isLoading: props.steps[0].isLoading(undefined),
        stepStates: props.startingState || [],
    });

    function connectState<T>(wizardStep: WizardStep<T>, index: number): WizardProps.Step {
        return {
            content: (
                <wizardStep.component
                    editMode={props.editMode}
                    index={index}
                    state={wizardState.stepStates[activeStepIndex] as T}
                    inError={wizardState.inError}
                    stepStates={wizardState.stepStates}
                    setNextClickHandler={(handler: () => boolean) => {
                        // Set the handler that the wizard will call to validate the step
                        // and determine if it can advance
                        wizardStep.handleNext = handler;
                    }}
                    giveState={(state: T) => {
                        setWizardState({
                            ...wizardState,
                            isLoading: wizardStep.isLoading(state),
                            stepStates: {
                                ...wizardState.stepStates,
                                [activeStepIndex]: state,
                            },
                        });
                    }}
                />
            ),
            ...wizardStep.props,
        };
    }

    const steps = props.steps.map(connectState);

    return (
        <Wizard
            i18nStrings={{
                stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
                collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
                cancelButton: 'Cancel',
                previousButton: 'Previous',
                nextButton: 'Next',
                submitButton: props.submit,
            }}
            onNavigate={({ detail }) => {
                const tryingToAdvance =
                    detail.reason === 'next' ||
                    (detail.reason === 'step' && detail.requestedStepIndex > activeStepIndex);

                // Ask the current step if it's valid and can advance
                let step = props.steps[activeStepIndex];
                wizardState.canAdvance = step.handleNext ? step.handleNext() : true;

                if (!wizardState.canAdvance && tryingToAdvance) {
                    setWizardState({
                        ...wizardState,
                        inError: true,
                    });
                } else {
                    const nextStep = props.steps[detail.requestedStepIndex];

                    setWizardState({
                        ...wizardState,
                        isLoading: nextStep.isLoading(wizardState.stepStates[detail.requestedStepIndex]),
                        activeStepIndex: detail.requestedStepIndex,
                    });
                    setActiveStepIndex(detail.requestedStepIndex);
                }
            }}
            onSubmit={() => props.onSubmit(wizardState.stepStates)}
            onCancel={props.onCancel}
            isLoadingNextStep={props.forceLoading || wizardState.isLoading}
            activeStepIndex={activeStepIndex}
            steps={steps}
        />
    );
};
