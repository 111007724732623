const selectedDefault: string[] = [
    'deviceRegistration',
    'frustrationFreeSetup',
    'overTheAir',
    'betaProgramManagement',
    'launchManagement',
    'supportEngineering',
    'localization',
    'deviceFieldConnectivityAssurance',
    'deviceEngagementMetrics',
    'qualityServices',
];

const excludeDefault: string[] = ['alexaTestPlatform', 'alexaDomain', 'alexaPreview', 'alexaApp'];

export const TemplateMappings: { [key: string]: { selected: string[]; exclude: string[] } } = {
    basic: {
        selected: selectedDefault,
        exclude: excludeDefault,
    },
    tablet: {
        selected: [
            'deviceRegistration',
            'frustrationFreeSetup',
            'overTheAir',
            'betaProgramManagement',
            'launchManagement',
            'supportEngineering',
            'localization',
            'deviceFieldConnectivityAssurance',
            'deviceEngagementMetrics',
            'qualityServices',
        ],
        exclude: ['alexaTestPlatform', 'alexaDomain', 'alexaPreview', 'alexaApp'],
    },
    echoonthego: {
        selected: [
            'alexaTestPlatform',
            'alexaDomain',
            'alexaPreview',
            'alexaApp',
            'deviceRegistration',
            'frustrationFreeSetup',
            'overTheAir',
            'launchManagement',
            'supportEngineering',
            'localization',
            'deviceFieldConnectivityAssurance',
            'deviceEngagementMetrics',
            'qualityServices',
        ],
        exclude: [],
    },
    echoheadless: {
        selected: [
            'alexaTestPlatform',
            'alexaDomain',
            'alexaPreview',
            'alexaApp',
            'deviceRegistration',
            'frustrationFreeSetup',
            'overTheAir',
            'launchManagement',
            'supportEngineering',
            'localization',
            'deviceFieldConnectivityAssurance',
            'deviceEngagementMetrics',
            'qualityServices',
        ],
        exclude: [],
    },
    echomultimodal: {
        selected: [
            'alexaTestPlatform',
            'alexaDomain',
            'alexaPreview',
            'alexaApp',
            'deviceRegistration',
            'frustrationFreeSetup',
            'overTheAir',
            'launchManagement',
            'supportEngineering',
            'localization',
            'deviceFieldConnectivityAssurance',
            'deviceEngagementMetrics',
            'qualityServices',
        ],
        exclude: [],
    },
    ereader: {
        selected: selectedDefault,
        exclude: excludeDefault,
    },
    firetv1p: {
        selected: selectedDefault,
        exclude: excludeDefault,
    },
    firetvedition: {
        selected: selectedDefault,
        exclude: excludeDefault,
    },
    smp: {
        selected: selectedDefault,
        exclude: excludeDefault,
    },
    smarthome: {
        selected: selectedDefault,
        exclude: excludeDefault,
    },
    coro: {
        selected: selectedDefault,
        exclude: excludeDefault,
    },
    ring: {
        selected: selectedDefault,
        exclude: excludeDefault,
    },
    blink: {
        selected: selectedDefault,
        exclude: excludeDefault,
    },
    eero: {
        selected: selectedDefault,
        exclude: excludeDefault,
    },
    healthandwellness: {
        selected: selectedDefault,
        exclude: excludeDefault,
    },
};

export const ServiceOrder = [
    'deviceRegistration',
    'frustrationFreeSetup',
    'overTheAir',
    'alexaApp',
    'alexaDomain',
    'alexaPreview',
    'alexaTestPlatform',
    'betaProgramManagement',
    'deviceEngagementMetrics',
    'deviceDistribution',
    'launchManagement',
    'localization',
    'deviceFieldConnectivityAssurance',
    'qualityServices',
    'supportEngineering',
];
