import { Environment, getEnvironment } from './environment';

const API_URL = {
    [Environment.test]: 'https://api-test-matgeyer.beta.mosaic.devices.amazon.dev/graphql',
    [Environment.beta]: 'https://api.beta.mosaic.devices.amazon.dev/graphql',
    [Environment.prod]: 'https://api.mosaic.devices.amazon.dev/graphql',
};

export const getAPIUrl = (): string => {
    return API_URL[getEnvironment()];
};
