import DropDownField from './DropDownField';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import CheckboxListField from './CheckboxListField';
import InfoBoxField from './InfoBoxField';
import RadioButtonsField from './RadioButtonsField';
import { Container, Header, Link, SpaceBetween } from '@amzn/awsui-components-react';
import DateField from './DateField';
import StringField from './StringField';
import MultiSelectField from './MultiSelectField';
import isEmpty from 'validator/lib/isEmpty';
import cloneDeep from 'lodash/cloneDeep';

export interface IOption {
    mosaicServiceId: string;
    name: string;
    handle: string;
    description: string;
    value: string;
}

export interface IField {
    name: string;
    type: string;
    required: boolean;
    handle: string;
    description: string;
    options: IOption[];
}

export interface IService {
    mosaicServiceId: string;
    name: string;
    description: string;
    infoUrl: string;
    fields: IField[];
}
export interface IServiceValues {
    [fieldHandle: string]: string;
}

export interface IServiceValuesMap {
    [serviceId: string]: IServiceValues;
}

export interface IIsValidCallback {
    (): boolean;
}

export interface IRegisterIsValidCallback {
    (callback: IIsValidCallback): void;
}

interface IOnChangeCallback {
    (path: string[], val: string | boolean | number | null): void;
}

interface IRegisterScrollIntoViewElement {
    (path: string[], element: HTMLElement): void;
}

interface IRenderServiceProps {
    registerScrollIntoViewElement: IRegisterScrollIntoViewElement;
    serviceValidationContext: IServiceValidationContext;
    onChange: IOnChangeCallback;
}

interface IFieldValidationContext {
    value: string;
    error: string;
}

interface IServiceValidationContext {
    [key: string]: IFieldValidationContext;
}

interface IValidationContext {
    [serviceId: string]: IServiceValidationContext;
}

interface IRenderServicesProps {
    services: IService[];
    isOnChangeValidationEnabled: boolean;
    registerIsValidCallback: IRegisterIsValidCallback;
    serviceValuesChanged: (serviceValuesMap: IServiceValuesMap) => void;
    getInitialServiceValues: () => IServiceValuesMap;
}

export interface IFieldProps {
    field: IField;
    service: IService;
    fieldValidationContext: IFieldValidationContext;
    onChange: IOnChangeCallback;
}

interface IRenderFieldProps {
    service: IService;
    serviceValidationContext: IServiceValidationContext;
    key: string | number;
    registerScrollIntoViewElement: IRegisterScrollIntoViewElement;
    onChange: IOnChangeCallback;
}

export const enum FieldTypes {
    dropdown = 'dropdown',
    checkbox_list = 'checkbox_list',
    date = 'date',
    info_box = 'info_box',
    radio_buttons = 'radio_buttons',
    string = 'string',
    multiselect = 'multiselect',
}

const FieldComponents: { [key in FieldTypes]: FC } = {
    dropdown: DropDownField,
    checkbox_list: CheckboxListField,
    date: DateField,
    info_box: InfoBoxField,
    radio_buttons: RadioButtonsField,
    string: StringField,
    multiselect: MultiSelectField,
};

const renderField = (field: IField, props: IRenderFieldProps) => {
    try {
        const { service, serviceValidationContext } = props;

        const Component = FieldComponents[field.type];
        if (!Component) {
            return <p {...props}>{field.type} not found</p>;
        }

        const fieldRef = useRef(null);

        useEffect(() => {
            if (!props.registerScrollIntoViewElement) {
                return;
            }
            props.registerScrollIntoViewElement([service.mosaicServiceId, field.handle], fieldRef.current);
        }, [fieldRef]);

        return (
            <div ref={fieldRef} key={props.key}>
                <Component
                    field={field}
                    fieldValidationContext={serviceValidationContext?.[field.handle] || null}
                    {...props}
                />
            </div>
        );
    } catch (err) {
        console.error(err);
        return <p {...props}>{field.type} failed to load</p>;
    }
};

const renderService = (service: IService, props: IRenderServiceProps) => {
    const fields = service.fields || [];
    return (
        <Container
            key={service.mosaicServiceId}
            header={
                <Header
                    variant="h2"
                    info={
                        <Link variant="info" target="_blank" rel="noopener noreferrer" href={service.infoUrl}>
                            Info
                        </Link>
                    }
                >
                    {service.name}
                </Header>
            }
        >
            <SpaceBetween direction="vertical" size="l">
                {fields?.map((f, k) =>
                    renderField(f, {
                        key: k,
                        onChange: props.onChange,
                        service: service,
                        serviceValidationContext: props.serviceValidationContext,
                        registerScrollIntoViewElement: props.registerScrollIntoViewElement,
                    }),
                )}
            </SpaceBetween>
        </Container>
    );
};

const convertValidationContextToValueMap = (
    services: IService[],
    validationContext: IValidationContext,
): IServiceValuesMap => {
    // Strip the error text from the service validation context
    // TODO: Eventually this will map the validated values to the shape the provision service expects
    return services.reduce((accum, service) => {
        accum[service.mosaicServiceId] = service.fields?.reduce((fieldAccum, field) => {
            fieldAccum[field.handle] = validationContext[service.mosaicServiceId][field.handle]?.value || '';
            return fieldAccum;
        }, {});
        return accum;
    }, {});
};

export const RenderServices: FC<IRenderServicesProps> = (props) => {
    const {
        services,
        getInitialServiceValues,
        isOnChangeValidationEnabled,
        registerIsValidCallback,
        serviceValuesChanged,
    } = props;
    const scrollIntoViewElements = useRef({});

    const [validationContext, setValidationContext] = useState<IValidationContext>({});

    useEffect(() => {
        // Initialize validation context with input service values
        const serviceValuesMap = getInitialServiceValues();

        let initializedValidationContext = services.reduce((accum, service) => {
            const serviceValues = serviceValuesMap[service.mosaicServiceId] || {};

            accum[service.mosaicServiceId] = service.fields?.reduce((fieldAccum, field) => {
                const fieldValue = serviceValues[field.handle] || '';

                fieldAccum[field.handle] = {
                    error: '',
                    value: fieldValue,
                };

                return fieldAccum;
            }, {});

            return accum;
        }, {});

        setValidationContext(initializedValidationContext);
    }, []);

    const validate = (): boolean => {
        let hasError = false;
        let firstErrorPath = null;

        let validatedContext = services.reduce((accum, service) => {
            const serviceContext = validationContext[service.mosaicServiceId] || {};

            accum[service.mosaicServiceId] = service.fields?.reduce((fieldAccum, field) => {
                const fieldValue = serviceContext[field.handle]?.value || '';
                const fieldError = validateServiceField([service.mosaicServiceId, field.handle], fieldValue);

                fieldAccum[field.handle] = {
                    error: fieldError,
                    value: fieldValue,
                };

                if (fieldError !== '') {
                    hasError = true;
                    firstErrorPath = firstErrorPath ?? [service.mosaicServiceId, field.handle];
                }

                return fieldAccum;
            }, {});

            return accum;
        }, {});

        setValidationContext(validatedContext);

        if (hasError) {
            // Scroll the first occurrence of a field with a validation error into view
            const [service, field] = firstErrorPath;
            scrollIntoViewElements.current[service][field]?.scrollIntoView({ block: 'center' });
        }

        return !hasError;
    };

    // Allow external consumer to request a validation of the input values
    if (registerIsValidCallback) {
        registerIsValidCallback(validate);
    }

    // Default validator
    // Todo: Customize error text based on control type & field name
    const required = (val) => (isEmpty(val) ? 'This field is required.' : '');

    const fieldValidators = useMemo(() => {
        return services.reduce((accum, s) => {
            accum[s.mosaicServiceId] = s.fields?.reduce((fieldAccum, f) => {
                // Eventually we could map the field's validation requirements to actual validator functions
                // e.g. fieldAccum[f.handle] = f.validationRules.map();
                fieldAccum[f.handle] = f.required ? [required] : [];

                return fieldAccum;
            }, {});

            return accum;
        }, {});
    }, [services]);

    const validateServiceField = (path, value) => {
        // All paths are [service.id,field.handle] right now.
        // Will need to update this if we introduce another level of grouping
        const [service, field]: string[] = path;
        const validators = fieldValidators[service][field] || [];

        for (let i = 0; i < validators.length; i++) {
            const err = validators[i](value);

            if (err !== '') {
                return err;
            }
        }
        return '';
    };

    const onChange = (path, value) => {
        const newValidationContext = cloneDeep(validationContext);
        const [service, field]: string[] = path;

        newValidationContext[service] = newValidationContext[service] ?? {};
        newValidationContext[service][field] = {
            value: value,
            error: isOnChangeValidationEnabled ? validateServiceField(path, value) : '',
        };

        // Notify external consumers of a change in the service values
        if (serviceValuesChanged) {
            serviceValuesChanged(convertValidationContextToValueMap(services, newValidationContext));
        }

        setValidationContext(newValidationContext);
    };

    const registerScrollIntoViewElement = (path, scrollIntoViewElement) => {
        const [service, field]: string = path;
        scrollIntoViewElements.current[service] = scrollIntoViewElements.current[service] ?? {};
        scrollIntoViewElements.current[service][field] = scrollIntoViewElement;
    };

    return (
        <SpaceBetween direction="vertical" size="l">
            {services
                .filter((s) => !!s?.fields?.length)
                .map((s) =>
                    renderService(s, {
                        serviceValidationContext: validationContext[s.mosaicServiceId],
                        onChange,
                        registerScrollIntoViewElement,
                    }),
                )}
        </SpaceBetween>
    );
};
