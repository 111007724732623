import {
    Button,
    CollectionPreferences,
    Header,
    Link,
    Pagination,
    StatusIndicator,
    Table,
    TextFilter,
} from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import EmptyTable from './EmptyTable';

interface Task {
    name: string;
    linkUrl: string;
    associatedService: string;
    dueDate: string;
    owner: string;
    state: TaskStates;
}

enum TaskStates {
    WAITING = 'Waiting',
    ASSIGNED = 'Assigned',
    COMPLETED = 'Completed',
}

const TaskState = ({ state }) => {
    let type;
    if (state === TaskStates.WAITING || state === TaskStates.ASSIGNED) {
        type = 'info';
    } else if (state === TaskStates.COMPLETED) {
        type = 'success';
    }
    return <StatusIndicator type={type}>{state}</StatusIndicator>;
};

const TasksPane = () => {
    const [preferences, setPreferences] = useState({
        pageSize: 10,
        visibleContent: ['name', 'associatedService', 'dueDate', 'owner', 'state'],
    } as any);

    // TODO: remove once we pull the tasks from Beacon
    const [taskList, setTaskList] = useState([] as Task[]);
    const [areTasksLoading, setAreTasksLoading] = useState(false);
    const mockTaskList: Task[] = [
        {
            name: 'Devices Security: Generate AKI private key identifier',
            linkUrl: 'https://t.corp.amazon.com/V652847825',
            associatedService: 'Device Registration',
            dueDate: '7/28/2022',
            owner: 'Security-DMS360',
            state: TaskStates.ASSIGNED,
        },
        {
            name: 'Edit Metadata in DMS360',
            linkUrl: 'https://t.corp.amazon.com/V653090808',
            associatedService: 'Device Registration',
            dueDate: '7/28/2022',
            owner: 'Dev-FTV-group',
            state: TaskStates.ASSIGNED,
        },
        {
            name: 'FFS: 1P Provisioner Onboarding Request',
            linkUrl: 'https://t.corp.amazon.com/V653086972',
            associatedService: 'Frustration Free Setup',
            dueDate: '7/28/2022',
            owner: 'BI-GROUP-FFS',
            state: TaskStates.ASSIGNED,
        },
        {
            name: 'FFS: New Provisionee Onboarding',
            linkUrl: 'https://t.corp.amazon.com/V653086153',
            associatedService: 'Frustration Free Setup',
            dueDate: '7/28/2022',
            owner: 'BI-GROUP-FFS',
            state: TaskStates.ASSIGNED,
        },
        // Manual Service Offerings Tasks
        {
            name: 'Distribute Prototype Devices to beta participants for LemonPie',
            linkUrl: '/',
            associatedService: 'Device Distribution',
            dueDate: '7/12/2022',
            owner: 'device-management-team',
            state: TaskStates.ASSIGNED,
        },
        {
            name: 'Beta Program Testing for LemonPie',
            linkUrl: '/',
            associatedService: 'Beta Program Management',
            dueDate: '9/5/2022',
            owner: 'device-beta-testing',
            state: TaskStates.ASSIGNED,
        },
        {
            name: 'Engage Support Engineering for LemonPie',
            linkUrl: '/',
            associatedService: 'Support Engineering',
            dueDate: '8/25/2022',
            owner: 'Digital-SE',
            state: TaskStates.ASSIGNED,
        },
        {
            name: 'Perform Device Field Connectivity Assurance for LemonPie',
            linkUrl: '/',
            associatedService: 'Device Field Connectivity Assurance',
            dueDate: '9/9/2022',
            owner: 'fqe',
            state: TaskStates.ASSIGNED,
        },
        {
            name: 'Engage Launch PM for LemonPie',
            linkUrl: '/',
            associatedService: 'Launch Management',
            dueDate: '7/25/2022',
            owner: 'pslm-group',
            state: TaskStates.ASSIGNED,
        },
        {
            name: 'Create Device Family in OTA Central for LemonPie',
            linkUrl: 'https://ota-central-integ.devices.amazon.dev/device-families',
            associatedService: 'OTA',
            dueDate: '9/9/2022',
            owner: 'LTPM',
            state: TaskStates.ASSIGNED,
        },
    ];

    const columnDefinitions = [
        {
            id: 'name',
            header: 'Name',
            cell: (item) => (
                <Link external externalIconAriaLabel="Opens in a new tab" href={item.linkUrl} variant="secondary">
                    {item.name}
                </Link>
            ),
            sortingField: 'name',
        },
        {
            id: 'associatedService',
            header: 'Associated Service',
            cell: (item) => item.associatedService,
            sortingField: 'associatedService',
        },
        {
            id: 'dueDate',
            header: 'Due Date',
            cell: (item) => item.dueDate,
            sortingField: 'dueDate',
        },
        {
            id: 'owner',
            header: 'Owner',
            cell: (item) => item.owner,
            sortingField: 'owner',
        },
        {
            id: 'state',
            header: 'State',
            cell: (item) => <TaskState state={item.state} />,
            sortingField: 'state',
        },
    ];

    const collectionPreferencesProps = {
        pageSizePreference: {
            title: 'Select page size',
            options: [
                { value: 1, label: '1 task' },
                { value: 10, label: '10 tasks' },
                { value: 20, label: '20 tasks' },
            ],
        },
        visibleContentPreference: {
            title: 'Select visible columns',
            options: [
                {
                    label: 'Task properties',
                    options: [
                        { id: 'name', label: 'Name', editable: false },
                        { id: 'associatedService', label: 'Associated Service', editable: false },
                        { id: 'dueDate', label: 'Due Date', editable: false },
                        { id: 'owner', label: 'Owner', editable: false },
                        { id: 'state', label: 'State', editable: false },
                    ],
                },
            ],
        },
    };

    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        taskList,
        {
            filtering: {
                empty: <EmptyTable title="No tasks" subtitle="No tasks to display" action={undefined} />,
                noMatch: (
                    <EmptyTable
                        title="No matches"
                        subtitle="No matching tasks found"
                        action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                    />
                ),
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: {
                defaultState: {
                    sortingColumn: columnDefinitions[2],
                },
            },
            selection: {},
        },
    );

    const { selectedItems } = collectionProps;

    const getMatchesCountText = (filteredItemsCount: number) => {
        if (filteredItemsCount === 1) {
            return `${filteredItemsCount} matching task found`;
        }
        return `${filteredItemsCount} matching tasks found`;
    };

    // TODO: This timer mocks out the provisioning process and loads the tasks after timer
    useEffect(() => {
        let provTimer;
        let taskLoadTimer;

        provTimer = setTimeout(() => {
            setTaskList(mockTaskList);
            setAreTasksLoading(true);
            taskLoadTimer = setTimeout(() => setAreTasksLoading(false), 2000);
        }, 10000);
        return () => {
            clearTimeout(provTimer);
            clearTimeout(taskLoadTimer);
        };
    }, []);

    return (
        <Table
            {...collectionProps}
            selectionType="single"
            header={
                <Header
                    variant="h2"
                    counter={
                        selectedItems.length ? `(${selectedItems.length}/${taskList.length})` : `(${taskList.length})`
                    }
                    info={<Link variant="info">Info</Link>}
                    actions={<Button>Actions</Button>}
                >
                    Tasks
                </Header>
            }
            columnDefinitions={columnDefinitions}
            visibleColumns={preferences.visibleContent}
            items={items}
            loading={areTasksLoading} // TODO: update when calling Beacon for Tasks
            loadingText="Loading tasks"
            pagination={
                <Pagination
                    {...paginationProps}
                    ariaLabels={{
                        nextPageLabel: 'Next page',
                        previousPageLabel: 'Previous page',
                        pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
                    }}
                />
            }
            filter={
                <TextFilter
                    {...filterProps}
                    countText={getMatchesCountText(filteredItemsCount)}
                    filteringPlaceholder="Find tasks"
                    filteringAriaLabel="Filter tasks"
                />
            }
            preferences={
                <CollectionPreferences
                    {...collectionPreferencesProps}
                    preferences={preferences}
                    cancelLabel="Cancel"
                    confirmLabel="Confirm"
                    title="Preferences"
                    onConfirm={({ detail }) => setPreferences(detail)}
                />
            }
        />
    );
};

export default TasksPane;
