import { IFieldProps } from './ServiceRenderer';
import { FormField, Select } from '@amzn/awsui-components-react';
import { FieldLabel } from './FieldLabel';
import { FC, useMemo } from 'react';

const DropDownField: FC<IFieldProps> = (props) => {
    const { field, service, fieldValidationContext, onChange } = props;
    const path = useMemo(() => {
        return [service.mosaicServiceId, field.handle];
    }, [service, field]);

    const labelMap = useMemo(() => {
        return field.options.reduce((accum, opt) => {
            accum[opt.value] = opt.name;
            return accum;
        }, {});
    }, [field]);

    const value = fieldValidationContext?.value || '';
    const error = fieldValidationContext?.error || '';

    const handleChange = ({ detail }) => {
        if (onChange) {
            onChange(path, detail.selectedOption.value);
        }
    };

    return (
        <FormField
            key={field.name}
            label={<FieldLabel label={field.name} required={field.required} />}
            description={field.description}
            errorText={error}
        >
            <Select
                selectedOption={{ label: labelMap[value], value }}
                onChange={handleChange}
                options={field.options.map((f) => ({ label: f.name, value: f.value }))}
                selectedAriaLabel="Selected"
            />
        </FormField>
    );
};

export default DropDownField;
